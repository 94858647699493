import { Button, Menu, Popover, MenuItem, Spinner } from "@blueprintjs/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { nodeFastServerAPI, secondServerAPI } from "../../utils/agent";
import { ApplicationState } from "../../../store";
import "./ConverterModule.css";

export default function ConverterModule() {
    const [fileSelectorFiles, setFileSelectorFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [call, setCall] = useState<number>();
    const [convertText, setConvertText] = useState("Convert to...");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        getFilesFromOldAPI();
    }, []);

    const auth = useSelector((state: ApplicationState) => state.auth);

    function handleSelectorClick() {
        getFilesFromOldAPI();
    }

    // async function getFilesFromOldAPI() {
    //     try {
    //         const response = await Axios.get(`${secondServerAPI}/rest/API/v1/ufc/getAllFiless`, {
    //             headers: {
    //                 'directory': 'all',
    //                 // 'user-id': 1,
    //                 // 'user-id': auth.User_id,
                    
    //             }
    //         });
    //         if (response.status === 200 && response.data.files) {
    //             setFileSelectorFiles(response.data.files);
    //         }
    //     } catch (error) {
    //         console.log("Error", error);
    //     }
    // }

    async function getFilesFromOldAPI() {
        try {
            const userId = Number(auth.User_id); 
            if (isNaN(userId)) {
                throw new Error("Invalid user ID");
            }
    
            const response = await Axios.get(`${secondServerAPI}/rest/API/v1/ufc/getAllFiless`, {
                headers: {
                    'directory': 'all',
                    'user-id': userId, 
                }
            });
            if (response.status === 200 && response.data.files) {
                setFileSelectorFiles(response.data.files);
            }
        } catch (error) {
            console.log("Error", error);
        }
    }
    

    async function handleConvert() {
        try {
            if (!call || !fileName) {
                setMessage("Please select a file and a conversion type.");
                return;
            }
            setLoading(true);
            setMessage("");

            const selectedFile = fileSelectorFiles.find(file => file.file_name === fileName);
            if (!selectedFile) {
                setMessage("File not found.");
                setLoading(false);
                return;
            }

            // Check file size limit
            if (selectedFile.file_size_in_mb > 110) {
                setMessage("The selected file exceeds the 110 MB size limit.");
                setLoading(false);
                console.log("File size limit exceeded");
                return;
            }

            let fromFileType = "";
            let toFileType = "";

            switch (call) {
                case 1:
                    fromFileType = "E57";
                    toFileType = "PLY";
                    break;
                case 2:
                    fromFileType = "E57";
                    toFileType = "STL";
                    break;
                case 3:
                    fromFileType = "E57";
                    toFileType = "GLTF";
                    break;
                case 4:
                    fromFileType = "PLY";
                    toFileType = "GLTF";
                    break;
                case 5:
                    fromFileType = "STL";
                    toFileType = "GLTF";
                    break;
                case 6:
                    fromFileType = "OBJ";
                    toFileType = "USD";
                    break;
                case 7:
                    fromFileType = "LAZ";
                    toFileType = "GLTF";
                    break;
                default:
                    setMessage("Invalid conversion type selected.");
                    setLoading(false);
                    return;
            }

            const url = `${secondServerAPI}/rest/api/v1/ufc/getFileS3/convert`;
            const response = await Axios.get(url, {
                headers: {
                    'file_name': fileName,
                    // 'user-id': 1,
                    'user-id': auth.User_id,
                    'file-id': selectedFile.file_id,
                    'from-file-type': fromFileType,
                    'to-file-type': toFileType,
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                setMessage("File Converted Successfully");

                let convertedFileName = 'converted_file';
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                    if (matches != null && matches[1]) { 
                        convertedFileName = matches[1].replace(/['"]/g, '');
                    }
                }

                // if (!convertedFileName.endsWith(`.${toFileType.toLowerCase()}`)) {
                //     convertedFileName += `.${toFileType.toLowerCase()}`;
                // }

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', convertedFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                setMessage("Conversion Failed");
            }
        } catch (error) {
            console.error("Error during conversion:", error);
            setMessage("Conversion Failed");
        } finally {
            setLoading(false);
        }
    }

    const handleMenuItemClick = (format: string, callType: number) => {
        setConvertText(`Convert to ${format}`);
        setCall(callType);
    }

    return (
        <div className="converter-module-container">
            <h1>Converter Module</h1>
            <p>Select a file and choose a format to convert.</p>
            <div>
                <select
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                >
                    <option value="" disabled onClick={handleSelectorClick}>Select a file</option>
                    {fileSelectorFiles.map((file) => (
                        <option key={file.file_id} value={file.file_name}>
                            {file.file_name} ({file.file_size_in_mb} MB)
                        </option>
                    ))}
                </select>

                <Popover content={
                    <Menu>
                        <MenuItem text="E57 TO PLY" onClick={() => handleMenuItemClick(".ply", 1)} />
                        <MenuItem text="E57 TO STL" onClick={() => handleMenuItemClick(".stl", 2)} />
                        <MenuItem text="E57 TO IDSV" onClick={() => handleMenuItemClick(".idsv", 3)} />
                        <MenuItem text="PLY to IDSV" onClick={() => handleMenuItemClick("PLY to IDSV", 4)} />
                        <MenuItem text="STL to IDSV" onClick={() => handleMenuItemClick("STL to IDSV", 5)} />
                        <MenuItem text="OBJ to USD" onClick={() => handleMenuItemClick("OBJ to USD", 6)} />
                        <MenuItem text="LAZ to IDSV" onClick={() => handleMenuItemClick("LAZ to IDSV", 7)} />
                    </Menu>
                } placement="bottom">
                    <Button alignText="left" icon="applications" rightIcon="caret-down" text={convertText} className="popover-button" />
                </Popover>
                <Button
                    className="convert-button"
                    alignText="center"
                    text={loading ? <Spinner size={16} /> : "Convert"}
                    onClick={handleConvert}
                    disabled={loading}
                />
                {message && <p className={`message ${message.includes("Successfully") ? "success" : "error"}`}>{message}</p>}
            </div>
        </div>

    );
}
