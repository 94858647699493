import React, { FunctionComponent, useMemo, useState } from "react";
import MenuButton from "../MenuButton";
import { InputDlg } from "../../common/InputDlg";
import OpenModelDlg from "./OpenModelDlg";
import {
  faCog,
  faFile,
  faFolderOpen,
  faSave,
  faClipboard,
  faBook,
  faDesktop,
  faChartPie,
  faAddressBook,
  faFileDownload,
  faFileUpload,
  faBoxOpen,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { createProject } from "../../../store/main/actions";
import {
  getCurrentUI,
  getCurrentProcess,
  saveGenericProject,
  openGenericProject,
} from "../../3d-models/utils";
import { createUIAction } from "../../../store/ui/actions";
import { SettingsDlg } from "./settings/SettingsDlg";
import { AvailableData } from "./available-data/AvailableData";
import { createPSSAction } from "../../../store/pss/actions";
import { createProcessAction } from "../../../store/process/actions";
import { useHistory } from "react-router-dom";
import { ModelType } from "../../../store/main/types";
import MTOContainer from "../../../pages/dashboard/MTOContainer";
import { CatalogueData } from "./available-data/CatalogueData";
import { LayoutData } from "./LayoutSavingDlg";
import { GetLayouts } from "./getLayoutsDlg";
import { Popover, Tooltip } from "@blueprintjs/core";
import { POPOVER } from "@blueprintjs/core/lib/esm/common/classes";
import { saveAs } from "file-saver";
import { UploadProject } from "./projectSavingDlg";
import { ProjectDownloadingDLG } from "./ProjectDownloadingDlg";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { UploadAsset } from "./AssetUploadDlg";
import { ProjectOpeningDlg } from "./ProjectOpeningDlg";

type Props = {};

const ProjectTab: FunctionComponent<Props> = () => {
  const [dialog, setDialog] = useState<JSX.Element>();

  const history = useHistory();

  function routeChange(url: string) {
    history.push(url);
  }

  const mode = useSelector((state: ApplicationState) => state.main.workMode);

  const controls = useSelector(
    (state: ApplicationState) => state.scene.controls
  );
  const currentProject = useSelector(
    (state: ApplicationState) => state.main.currentProject
  );
  const projects = useSelector(
    (state: ApplicationState) => state.main.projects
  );
  const fabricatedSections = useSelector(
    (state: ApplicationState) => state.main.fabricatedSections
  );
  const rolledSections = useSelector(
    (state: ApplicationState) => state.main.rolledSections
  );
  const combinedSections = useSelector(
    (state: ApplicationState) => state.main.combinedSections
  );
  const userDefinedElbows = useSelector(
    (state: ApplicationState) => state.main.userDefinedElbows
  );
  const ui = useSelector((state: ApplicationState) => getCurrentUI(state));
  const process = useSelector((state: ApplicationState) =>
    getCurrentProcess(state)
  );

  const dispatch = useDispatch();

  const userRole_orig = useSelector(
    (state: ApplicationState) => state.userRole
  );
  
  const auth = useSelector((state: ApplicationState) => state.auth);

  const project = useMemo(() => {
    return projects.find((project) => project.name === currentProject);
  }, [projects, currentProject]);

  function handleCloseOpenModelDlg(file?: File, type?: ModelType | undefined) {
    openGenericProject(dispatch, file, mode, projects, type,auth.User_id).finally(() =>
      setDialog(undefined)
    );
  }

  function handleCloseInputDlg(name?: string) {
    if (name) {
      dispatch(createProject(name));
      dispatch(createUIAction(name));
      dispatch(createProcessAction(name));
      dispatch(createPSSAction(name));
    }
    setDialog(undefined);
  }

  function handleCreateProject() {
    setDialog(
      <InputDlg title={`Create new project`} onClose={handleCloseInputDlg} />
    );
  }

  function handleOpenProject() {
    let extensions;
    switch (mode) {
      case "PIPING":
        extensions = [".pps", ".ddd"];
        break;
      case "STRUCTURE":
        extensions = [".pds", ".ods", ".ddd"];
        break;
      case "PROCESS":
        extensions = [".psm", ".p2p", ".ddd"];
        break;
      case "DESIGNER":
        extensions = [".psm", ".p2p", ".pds", ".ods", ".pps", ".ddd"];
    }
    setDialog(
      <OpenModelDlg
        mode={mode}
        onClose={handleCloseOpenModelDlg}
        extensions={extensions}
      />
    );
  }
  
  // function handleOpenProject() {
  //   setDialog(
  //     <ProjectOpeningDlg
  //       onClose={handleCloseOpenModelDlg}
  //     />
  //   );
  // }

  function handleSave() {
    saveGenericProject(
      project,
      ui,
      process,
      mode,
      fabricatedSections,
      rolledSections,
      combinedSections,
      userDefinedElbows,
      controls
    );
  }

  function handleOpenSettings() {
    setDialog(<SettingsDlg onClose={() => setDialog(undefined)} />);
  }

  function handleOpenAvailableData() {
    setDialog(<AvailableData onClose={() => setDialog(undefined)} />);
  }

  function handleOpenDashboard() {
    // routeChange("/dashboard");
    routeChange("/modes");
  }

  function handleOpenCatalogue() {
    setDialog(<CatalogueData onClose={() => setDialog(undefined)} />);
  }

  function handleSaveLayout(type: string) {
    setDialog(<LayoutData onClose={() => setDialog(undefined)} stype={type} />);
  }

  function handleGetLayout() {
    setDialog(<GetLayouts onClose={() => setDialog(undefined)} />);
  }

  function handleOpenMTO() {}

  function handleUploadProject() {
    setDialog(<UploadProject onClose={() => setDialog(undefined)} />);
  }

  function handleDownloadfromCloudProject() {
    setDialog(<ProjectDownloadingDLG onClose={() => setDialog(undefined)} />);
  }

  function handleAssetUpload() {
    setDialog(
      <UploadAsset onClose={() => setDialog(undefined)} />
    );
  }

  function handleRedirect() {
    routeChange("/viewer");
    // window.open("/viewer", "_blank");
  }

  function handleIDSPCD() {
    window.open("https://idspcdloader.asets.io/m5n6.html", "_blank");
  }  

  return (
    <div className="d-flex">
      {dialog}
      <MenuButton
        id="new-project"
        text="New"
        icon={faFile}
        onClick={handleCreateProject}
      />
      {/* {userRole_orig.userRole === ("teamMember") &&(
      <MenuButton
        id="open-project"
        text="Open"
        icon={faFolderOpen}
        onClick={handleOpenProject}
      />
    )}
      {userRole_orig.userRole === ("Manager" || "Team Lead") && ( */}
      <Popover
        interactionKind={"click"}
        content={
          <div className="menu-bar-subgroup">
            <MenuButton
              id="open-project"
              text="Open Locally"
              icon={faBoxOpen}
              onClick={handleOpenProject}
            />
            
              <MenuButton
                text={"Get-from-Cloud"}
                id="downlaod"
                icon={faFileDownload}
                onClick={handleDownloadfromCloudProject}
              />
          </div>
        }
        target={<MenuButton icon={faFile} text="Open Project" />}
      />
      {/* )} */}
      {/* {userRole_orig.userRole === ("Team Member") && (
      <MenuButton
        id="save-project"
        text="Save"
        icon={faSave}
        onClick={handleSave}
      />
    )}
      {userRole_orig.userRole === ("Manager" || "Team Lead") && ( */}
      <Popover
        interactionKind={"click"}
        content={
          <div className="menu-bar-subgroup">
            <MenuButton
              id="save-project"
              text="Save Locally"
              icon={faSave}
              onClick={handleSave}
            />
              <MenuButton
                text={"Upload-On-Cloud"}
                id="Upload"
                icon={faFileUpload}
                onClick={handleUploadProject}
              />
          </div>
        }
        target={<MenuButton icon={faSave} text="Save Project" />}
      />
      {/* )} */}
      <MenuButton
        id="project-settings"
        text="Settings"
        icon={faCog}
        onClick={handleOpenSettings}
      />
      <MenuButton
        id="asset-upload"
        text = "Upload Assets"
        icon={faUpload}
        onClick={handleAssetUpload}
      />
      <MenuButton
        id="project-catalogue"
        text="Catalogue"
        icon={faBook}
        onClick={handleOpenAvailableData}
      />
      {mode === "DESIGNER" ? (
        <MenuButton
          id="project-dashboard"
          icon={faDesktop}
          text="Dashboard"
          onClick={handleOpenDashboard}
        />
      ) : null}
      <Popover
        interactionKind={"click"}
        content={
          <div className="menu-bar-subgroup">
            <Popover
              content={
                <div className="menu-bar-subgroup">
                  <MenuButton
                    text="Process Layout"
                    icon={faClipboard}
                    onClick={() => handleSaveLayout("Process")}
                  />
                  <MenuButton
                    text="Piping Layout"
                    icon={faFileDownload}
                    onClick={() => handleSaveLayout("Piping")}
                  />
                  <MenuButton
                    text="Structural Layout"
                    icon={faChartPie}
                    onClick={() => handleSaveLayout("Structural")}
                  />
                </div>
              }
              target={<MenuButton icon={faFileUpload} text="Save" />}
            />
            <MenuButton
              text={"Get"}
              id="Get Layout"
              icon={faFileDownload}
              onClick={handleGetLayout}
            />
          </div>
        }
        target={<MenuButton icon={faAddressBook} text="Layout" disabled={true} />}
      />
      {/* <MenuButton text="Open IDSV Viewer" onClick={handleRedirect} /> */}
      {/* <MenuButton bpIcon={<Icon size={24} icon={IconNames.EYE_ON} />} text="Open IDS PCD" onClick={handleIDSPCD} /> */}
      {}
    </div>
  );
};

export default ProjectTab;
