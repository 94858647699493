// import React, { useEffect, useState } from 'react';
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { Camera, Object3D, Scene, AmbientLight, PointLight, DirectionalLight } from 'three';
// import { ButtonGroup, Button, Tooltip } from '@blueprintjs/core';
// import './TransformerControlsComponent.css';

// interface TransformControlsComponentProps {
//   camera: Camera;
//   scene: Scene;
//   canvas: HTMLCanvasElement;
//   controls: OrbitControls | null;
//   item: Object3D;
//   onClose: () => void;
//   onDelete: () => void;
//   onClone: (clonedItem: Object3D) => void;
// }

// const TransformControlsComponent: React.FC<TransformControlsComponentProps> = ({
//   camera,
//   scene,
//   canvas,
//   controls,
//   item,
//   onClose,
//   onDelete,
//   onClone,
// }) => {
//   const [transformControls, setTransformControls] = useState<TransformControls | null>(null);
//   const [mode, setMode] = useState<'translate' | 'rotate' | 'scale'>('translate');

//   useEffect(() => {
//     // Ensure item's world matrix is updated
//     item.updateMatrixWorld(true); 

//     // Optionally, center the pivot if the object's pivot is far off
//     // if (item.geometry) {
//     //   item.geometry.computeBoundingBox();
//     //   const boundingBox = item.geometry.boundingBox;
//     //   if (boundingBox) {
//     //     const center = new THREE.Vector3();
//     //     boundingBox.getCenter(center);
//     //     item.geometry.translate(-center.x, -center.y, -center.z);
//     //   }
//     // }

//     const transformControls = new TransformControls(camera, canvas);
//     transformControls.attach(item);
//     scene.add(transformControls);

//     const onChange = () => {
//       controls && controls.update();
//     };

//     const onDraggingChanged = (event: any) => {
//       if (controls) {
//         controls.enabled = !event.value;
//       }
//     };

//     transformControls.addEventListener('change', onChange);
//     transformControls.addEventListener('dragging-changed', onDraggingChanged);

//     setTransformControls(transformControls);

//     return () => {
//       if (transformControls) {
//         transformControls.removeEventListener('change', onChange);
//         transformControls.removeEventListener('dragging-changed', onDraggingChanged);
//         transformControls.detach();
//         scene.remove(transformControls);
//         if (controls) {
//           controls.enabled = true;
//         }
//       }
//     };
//   }, [camera, canvas, controls, item, scene]);

//   const handleModeChange = (newMode: 'translate' | 'rotate' | 'scale') => {
//     setMode(newMode);
//     if (transformControls) {
//       transformControls.setMode(newMode);
//     }
//   };

//   const handleClone = () => {
//     const clonedItem = item.clone();
//     scene.add(clonedItem);
//     onClone(clonedItem);
//   };

//   const handleClose = () => {
//     onClose();
//     if (transformControls) {
//       transformControls.detach();
//     }
//   };

//   useEffect(() => {
//     const handleKeyDown = (event: KeyboardEvent) => {
//       if (event.ctrlKey) {
//         switch (event.key) {
//           case '1':
//             event.preventDefault();
//             handleModeChange('translate');
//             break;
//           case '2':
//             event.preventDefault();
//             handleModeChange('rotate');
//             break;
//           case '3':
//             event.preventDefault();
//             handleModeChange('scale');
//             break;
//           case 'c':
//             event.preventDefault();
//             handleClone();
//             break;
//           case 'Delete':
//             event.preventDefault();
//             onDelete();
//             break;
//           case 'x':
//             event.preventDefault();
//             handleClose();
//             break;
//           default:
//             break;
//         }
//       }
//     };

//     window.addEventListener('keydown', handleKeyDown);

//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [transformControls, onClose, onDelete, handleClone]);

//   return (
//     <div className="transform-controls-container">
//       <ButtonGroup vertical>
//         <Tooltip content="Translate the object" className="transform-controls-tooltip">
//           <Button
//             icon="arrows-horizontal"
//             active={mode === 'translate'}
//             onClick={() => handleModeChange('translate')}
//             className={`transform-controls-button ${mode === 'translate' ? 'active' : ''}`}
//           />
//         </Tooltip>
//         <Tooltip content="Rotate the object" className="transform-controls-tooltip">
//           <Button
//             icon="repeat"
//             active={mode === 'rotate'}
//             onClick={() => handleModeChange('rotate')}
//             className={`transform-controls-button ${mode === 'rotate' ? 'active' : ''}`}
//           />
//         </Tooltip>
//         <Tooltip content="Scale the object" className="transform-controls-tooltip">
//           <Button
//             icon="zoom-to-fit"
//             active={mode === 'scale'}
//             onClick={() => handleModeChange('scale')}
//             className={`transform-controls-button ${mode === 'scale' ? 'active' : ''}`}
//           />
//         </Tooltip>
//         <Tooltip content="Clone the object" className="transform-controls-tooltip">
//           <Button
//             icon="duplicate"
//             onClick={handleClone}
//             className="transform-controls-button"
//           />
//         </Tooltip>
//         <Tooltip content="Delete the object" className="transform-controls-tooltip">
//           <Button 
//             icon="trash" 
//             onClick={onDelete} 
//             className="transform-controls-button" 
//           />
//         </Tooltip>
//         <Tooltip content="Close controls" className="transform-controls-tooltip">
//           <Button 
//             icon="cross" 
//             onClick={onClose} 
//             className="transform-controls-button" 
//           />
//         </Tooltip>
//       </ButtonGroup>
//     </div>
//   );
// };

// export default TransformControlsComponent;






import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Camera, Object3D, Scene } from 'three';
import { ButtonGroup, Button, Tooltip } from '@blueprintjs/core';
import './TransformerControlsComponent.css';

interface TransformControlsComponentProps {
  camera: Camera;
  scene: Scene;
  canvas: HTMLCanvasElement;
  controls: OrbitControls | null;
  item: Object3D;
  onClose: () => void;
  onDelete: () => void;
  onClone: (clonedItem: Object3D) => void;
}

const TransformControlsComponent: React.FC<TransformControlsComponentProps> = ({
  camera,
  scene,
  canvas,
  controls,
  item,
  onClose,
  onDelete,
  onClone,
}) => {
  const [transformControls, setTransformControls] = useState<TransformControls | null>(null);
  const [mode, setMode] = useState<'translate' | 'rotate' | 'scale'>('translate');
  const pivotRef = useRef<THREE.Object3D | null>(null);
  const isDeletedRef = useRef(false);
  
  useEffect(() => {
    item.updateMatrixWorld(true); 
  
    const box = new THREE.Box3().setFromObject(item);
    const center = box.getCenter(new THREE.Vector3());

    const pivot = new THREE.Object3D();
    pivot.position.copy(center);
    scene.add(pivot);
  
    item.position.sub(center);
    pivot.add(item);
  
    pivotRef.current = pivot;
  
    const transformControls = new TransformControls(camera, canvas);
    transformControls.attach(pivot);
    scene.add(transformControls);
  
    const onChange = () => {
      if (controls) controls.update();
    };
  
    const onDraggingChanged = (event: any) => {
      if (controls) {
        controls.enabled = !event.value;
      }
    };
  
    transformControls.addEventListener('change', onChange);
    transformControls.addEventListener('dragging-changed', onDraggingChanged);
  
    setTransformControls(transformControls);
  
    return () => {
      if (transformControls) {
        transformControls.removeEventListener('change', onChange);
        transformControls.removeEventListener('dragging-changed', onDraggingChanged);
        transformControls.detach(); 
        scene.remove(transformControls); 
        if (controls) {
          controls.enabled = true; 
        }
      }
  
      if (pivotRef.current) {
        pivotRef.current.remove(item);
        scene.remove(pivotRef.current);
      }
  
      if (!isDeletedRef.current) {
        item.updateMatrixWorld(true);
        if (pivotRef.current) {
          item.applyMatrix4(pivotRef.current.matrixWorld);
        }
        scene.add(item);
      }
    };
  }, [camera, canvas, controls, item, scene]);

  const handleModeChange = (newMode: 'translate' | 'rotate' | 'scale') => {
    setMode(newMode);
    if (transformControls) {
      transformControls.setMode(newMode);
    }
  };

  const handleClone = () => {
    const clonedItem = item.clone();
    clonedItem.position.set(0, 0, 0);
    clonedItem.rotation.set(0, 0, 0);
    clonedItem.scale.set(1, 1, 1);
    clonedItem.updateMatrix();
    clonedItem.updateMatrixWorld(true);
    scene.add(clonedItem);
    onClone(clonedItem);
  };
  

  const handleDelete = () => {
    isDeletedRef.current = true;

    if (pivotRef.current) {
      pivotRef.current.remove(item);
      scene.remove(item);
      scene.remove(pivotRef.current);
    }

    if (transformControls) {
      transformControls.detach();
      scene.remove(transformControls);
    }

    onDelete();
  };

  const handleClose = () => {
    onClose();
    if (transformControls) {
      transformControls.detach();
      scene.remove(transformControls);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        switch (event.key) {
          case '1':
            event.preventDefault();
            handleModeChange('translate');
            break;
          case '2':
            event.preventDefault();
            handleModeChange('rotate');
            break;
          case '3':
            event.preventDefault();
            handleModeChange('scale');
            break;
          case 'c':
            event.preventDefault();
            handleClone();
            break;
          case 'Delete':
            event.preventDefault();
            handleDelete();
            break;
          case 'x':
            event.preventDefault();
            handleClose();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [transformControls, handleClone]);

  return (
    <div className="transform-controls-container">
      <ButtonGroup vertical>
        <Tooltip content="Translate the object" className="transform-controls-tooltip">
          <Button
            icon="arrows-horizontal"
            active={mode === 'translate'}
            onClick={() => handleModeChange('translate')}
            className={`transform-controls-button ${mode === 'translate' ? 'active' : ''}`}
          />
        </Tooltip>
        <Tooltip content="Rotate the object" className="transform-controls-tooltip">
          <Button
            icon="repeat"
            active={mode === 'rotate'}
            onClick={() => handleModeChange('rotate')}
            className={`transform-controls-button ${mode === 'rotate' ? 'active' : ''}`}
          />
        </Tooltip>
        <Tooltip content="Scale the object" className="transform-controls-tooltip">
          <Button
            icon="zoom-to-fit"
            active={mode === 'scale'}
            onClick={() => handleModeChange('scale')}
            className={`transform-controls-button ${mode === 'scale' ? 'active' : ''}`}
          />
        </Tooltip>
        <Tooltip content="Clone the object" className="transform-controls-tooltip">
          <Button
            icon="duplicate"
            onClick={handleClone}
            className="transform-controls-button"
          />
        </Tooltip>
        <Tooltip content="Delete the object" className="transform-controls-tooltip">
          <Button
            icon="trash"
            onClick={handleDelete}
            className="transform-controls-button"
          />
        </Tooltip>
        <Tooltip content="Close controls" className="transform-controls-tooltip">
          <Button
            icon="cross"
            onClick={handleClose}
            className="transform-controls-button"
          />
        </Tooltip>
      </ButtonGroup>
    </div>
  );
};

export default TransformControlsComponent;

